import React from 'react'
import { Helmet } from 'react-helmet'
import './styles/about.css'
import Sidebar from '../Sidebar/Sidebar'
import Expander from '../Nav/js/expandMobileNav'
import Collapser from '../Nav/js/collapseMobileNav'

const About = () => {
  return (
    <section className='container' id='aboutRoot'>
      <Helmet>
          <title>Home Loans  in Louisville La Grange Crestwood Kentucky | Integra Mortgage, Home Loans in Louisville, LaGrange, Crestwood and Kentucky</title>
          <meta name='description' content='Integra Mortgage Group in Louisville and LaGrange Kentucky specializes in mortgage loans for new home purchases and refinances. Need a real estate loan in Louisville?' />
          <meta name='keywords' content='real estate,loan,mortgage,house,louisville,lagrange,la grange,crestwood,pewee valley,buckner,prospect,anchorage,shelbyville,simpsonville,fisherville,mount washington,brooks,shepherdsville' />
      </Helmet>
      <div className="moduleOverlay" id='moduleOverlay' onClick={Collapser}></div>
      <div className="container-max" id='aboutContent'>
        <h1>About Integra Mortgage Group</h1>
        <p>
          <img src="https://integra.imgix.net/about_hills.jpg" alt="" />
          Integra Mortgage Group has been committed to providing our customers with the highest level of service and the lowest mortgage loan rates available since 1997. Our goal is simple:  To make the mortgage loan process as convenient and hassle-free as possible for our customers. Our high service standards and commitment to quality assures that you will receive prompt loan approval, full program disclosures, no hidden costs, and a wide variety of mortgage options to choose from.
        </p>
        <p>Our loan application process is simple and FREE and, in most cases, we can get same-day approval.  Whether you are purchasing or building a new home, refinancing your current home, or just need extra cash from the equity in your home for any reason, we can provide a mortgage loan to fit any home financing need.</p>
        <h4>We offer:</h4>
        <ul>
          <li>Several closing cost options</li>
          <li>Primary property loans</li>
          <li>Investment property loans</li>
          <li>Second home loans</li>
          <li>And many other financing options</li>
        </ul>
        <p>We are experienced and knowledgeable and are available to answer all of your questions, explain your various mortgage loan alternatives, and provide you a mortgage loan plan to fit your home financing needs. Contact us today for your best home financing experience!  502-314-5626.</p>
        <div className="flex flex-ai-bl flex-jc-sb">
          <a href="https://integramortgagegroup.com/contact">
            <button className='btn btn-cta'>
              Contact Us!
            </button>
          </a>
          <img src="https://integra.imgix.net/EHO.jpg" alt="" className='flex flex-as-c' />
        </div>
      </div>
      <Sidebar />
    </section>
  )
}

export default About