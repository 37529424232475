import React from 'react'
import { Helmet } from 'react-helmet'
import './styles/mortgagePrograms.css'
import Sidebar from '../Sidebar/Sidebar'
import Expander from '../Nav/js/expandMobileNav'
import Collapser from '../Nav/js/collapseMobileNav'

const MortgagePrograms = () => {
  return (
    <section className='container' id='programsRoot'>
    <Helmet>
        <title>Mortgage Programs | Integra Mortgage, Home Loans in Louisville, LaGrange, Crestwood and Kentucky</title>
        <meta name='description' content='Integra Mortgage Group in Louisville and LaGrange Kentucky specializes in mortgage loans for new home purchases and refinances. Need a real estate loan in Louisville?' />
        <meta name='keywords' content='real estate,loan,mortgage,house,louisville,lagrange,la grange,crestwood,pewee valley,buckner,prospect,anchorage,shelbyville,simpsonville,fisherville,mount washington,brooks,shepherdsville' />
    </Helmet>
    <div className="moduleOverlay" id='moduleOverlay' onClick={Collapser}></div>
    <div className="container-max" id='programsContent'>
      <h1>Mortgage Programs</h1>
      <p><strong>Conventional (FNMA & FHLMC), FHA, VA, USDA, and more!</strong></p>
      <p><strong>Fixed Rate</strong> - 30, 20, 15 and 10 year terms that provide stability and consistency for budget conscious borrowers.</p>
      <p><strong>Adjustable Rate Mortgage (ARM)</strong> - The interest rate can fluctuate up or down within rate cap and adjustment period guidelines.  This option has historically cost less over the life of the loan. There are several sub-categories for this loan type; 1 Year ARM - the interest rate can adjust up or down annually; 3/1, 5/1, 7/1 and 10/1 ARMs - the interest rate is fixed for 3, 5, 7 or 10 years then can adjust annually thereafter.</p>
      <p><strong>“Interest Only” Option</strong> - Allows borrowers to pay only the interest portion of their mortgage loan. Ideal for borrowers desiring to maximize their cash flow.</p>
      <p><strong>“No Money Down” Programs</strong> - No down payment requirements for borrowers with little or no savings. In most cases, closing costs can be included in the loan. Certain geographical restrictions may apply.</p>
      <p><strong>Construction Loans</strong> - Interim financing for those desiring to build their dream home rather than buy an existing home. This loan is short-term, generally for the construction phase of your project, and easily converted to permanent financing upon completion.</p>
      <p><strong>Commercial Loans</strong> - Financing for business purposes.</p>
      <p><strong>Other Programs</strong> - Home Equity Lines of Credit (HELOC), 2nd Mortgage Installment Loans, Reverse Mortgages.</p>
    </div>
    <Sidebar />
  </section>
  )
}

export default MortgagePrograms