import React from 'react'
import './styles/contact-form.css'

const ContactForm = () => {
  return (
    <form
        id='contact-form'
        name='contact-form'
        method='POST'
        action='/thank-you'
        netlify-honeypot="bot-field"
        data-netlify='true'
    >
      <p class="hidden">
        <label>
          Don't fill this field out if you're human: <input name="bot-field" />
        </label>
      </p>
      <input type="hidden" name='form-name' value="contact-form" />
      <div className="form-row split">
        <div className="form-element">
          <label htmlFor="name">Name</label>
          <input type="text" name='name' />
        </div>
        <div className="form-element">
          <label htmlFor="email">Email</label>
          <input type="text" name='email' />
        </div>
      </div>
      <div className="form-row">
        <div className="form-element">
          <label htmlFor="message">Message</label>
          <textarea name='message'></textarea>
        </div>
      </div>
      <div className="form-row shrink">
        <button type='submit'>Submit</button>
      </div>
    </form>
  )
}

export default ContactForm