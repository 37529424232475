import React from 'react'
import { Helmet } from 'react-helmet'
import './styles/faq.css'
import Sidebar from '../Sidebar/Sidebar'
import Expander from '../Nav/js/expandMobileNav'
import Collapser from '../Nav/js/collapseMobileNav'
import {NavLink} from "react-router-dom"

const FAQ = () => {
  return (
    <section className='container' id='faqRoot'>
      <Helmet>
          <title>FAQ | Integra Mortgage, Home Loans in Louisville, LaGrange, Crestwood and Kentucky</title>
          <meta name='description' content='Integra Mortgage Group in Louisville and LaGrange Kentucky specializes in mortgage loans for new home purchases and refinances. Need a real estate loan in Louisville?' />
          <meta name='keywords' content='real estate,loan,mortgage,house,louisville,lagrange,la grange,crestwood,pewee valley,buckner,prospect,anchorage,shelbyville,simpsonville,fisherville,mount washington,brooks,shepherdsville' />
      </Helmet>
      <div className="moduleOverlay" id='moduleOverlay' onClick={Collapser}></div>
      <div className="container-max" id='faqContent'>
        <h1>FAQ's</h1>
        <p><strong>Looking for a new mortgage in Louisville or surrounding areas? These are some of the questions you may have!</strong></p>
        <div className="faq-tableOfContents">
          <ol>
            <li><a href="#I-owe-more-than-my-house-is-worth">I owe more than my house is worth</a></li>
            <li><a href="#I-cant-afford-a-big-down-payment">I can't afford a big down payment</a></li>
          </ol>
        </div>
        <div className="faq-wrapper">
          <div className="faq" id='I-owe-more-than-my-house-is-worth'>
            <h2 className='question'>The value of my home has decreased over the past several years to where I now owe more than it's worth. Can I still refinance?</h2>
            <p className='answer'>Yes, you still may be able to refinance under certain conditions.  The government has established refinance programs specifically for that purpose.</p>
          </div>
          <div className="faq" id='I-cant-afford-a-big-down-payment'>
            <h2 className='question'>I'd like to purchase a home but I'm having difficulty saving for a down payment.  Are there any mortgage programs available that don't require much, if any, money down?</h2>
            <p className='answer'>Yes, there are several mortgage programs available that will require minimum down payment amounts.  We also offer a mortgage program that has NO down payment requirement with certain property and income eligibility restrictions.</p>
          </div>
        </div>
      </div>
      <Sidebar />
    </section>
  )
}

export default FAQ