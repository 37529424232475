import React from 'react'
import { Helmet } from 'react-helmet'
import './styles/not-found.css'
import Sidebar from '../Sidebar/Sidebar'
import Expander from '../Nav/js/expandMobileNav'
import Collapser from '../Nav/js/collapseMobileNav'

const NotFound = () => {
  return (
    <section className='container' id='notFoundRoot'>
      <Helmet>
          <title>404 - Not Found | Integra Mortgage Group of Louisville KY</title>
          <meta name='description' content='404 - Page not found' />
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
      </Helmet>
      <div className="moduleOverlay" id='moduleOverlay' onClick={Collapser}></div>
      <div className="container-max" id='notFoundContent'>
        <h1>404 - Page not found</h1>
        <p><a href="https://integramortgagegroup.com/">Take me back home</a></p>
      </div>
      <Sidebar />
    </section>
  )
}

export default NotFound