import React from 'react'
import { Helmet } from 'react-helmet'
import './styles/apply.css'
import '../Card/styles/card.css'
import Sidebar from '../Sidebar/Sidebar'
import Expander from '../Nav/js/expandMobileNav'
import Collapser from '../Nav/js/collapseMobileNav'
import applyOnSubmit from './js/applyOnSubmit'
import {MdOutlineKeyboardArrowRight} from 'react-icons/md'

const Apply = () => {
  return (
    <section className='container' id='applyRoot'>
      <Helmet>
          <title>Apply | Integra Mortgage, Home Loans in Louisville, LaGrange, Crestwood and Kentucky</title>
          <meta name='description' content='Integra Mortgage Group in Louisville and LaGrange Kentucky specializes in mortgage loans for new home purchases and refinances. Need a real estate loan in Louisville?' />
          <meta name='keywords' content='real estate,loan,mortgage,house,louisville,lagrange,la grange,crestwood,pewee valley,buckner,prospect,anchorage,shelbyville,simpsonville,fisherville,mount washington,brooks,shepherdsville' />
      </Helmet>
      <div className="moduleOverlay" id='moduleOverlay' onClick={Collapser}></div>
      <div className="container-max" id='applyContent'>
        <h1>Apply Now</h1>
        <div className="flex blinkWrapper">
          <div className="blinkItem">
            <h3>Blink and You're Home!</h3>
            <p>This is an easy online mortgage application. It's safe and secure. Complete it on your own, or with one of our loan experts.</p>
          </div>
        </div>
        <div className="flex blinkWrapper">
          <div className="blinkItem">
            <img src="https://integra.imgix.net/blink.jpg" alt="" />
          </div>
          <div className="blinkItem">
            <div className="card-wrapper">
              <div className="card">
                <div className="cta-id">
                  <h1>Apply in a Blink!</h1>
                  <h2>Ready to apply for a mortgage?</h2>
                </div>
                <a href="https://www.blink.mortgage/app/signup/p/integramortgagegroupllc" rel='noopener' target="blank">
                  <div className="btn btn-primary">
                    Apply Now!
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="blinkitem">
            <div className="card-wraper">
              <div className="card">
                <div className="cta-id">
                  <form
                    id='apply'
                    name='apply'
                    method='POST'
                    data-netlify='true'
                    onSubmit={applyOnSubmit}
                  >
                    <input type="hidden" name='form-name' value="apply" />
                    <label for="referral">Where did you hear about us?</label>
                    <select id="referral" name="Referral is" required>
                      <option disabled selected value style={{ display: 'none'}}> -- select an option -- </option>
                      <option value="SEO">I found you on Google!</option>
                      <option value="word of mouth">Word of mouth</option>
                      <option value="Skip sent me">Skip sent me</option>
                    </select>
                    <input class="cta" type="submit" value="Apply Now!" id="submit"></input>
                  </form>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Sidebar />
    </section>
  )
}

export default Apply