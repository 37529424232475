import React from 'react'
import './styles/footer.css'
import {FiCopy} from 'react-icons/fi'
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs'

const Footer = () => {
  return (
    <footer className="container-max">
      <div className="container flex" id='footerMain'>
        <div className="contact">
          <p>Integra Mortgage Group LLC</p>
          <div className="phone flex">
            <a href="tel:+15023145626">502-314-5626</a>
              <div className="copyLink">
                <FiCopy className='copyIcon'/>
                <p>click here to copy</p>
              </div>
          </div>
          <div className="email flex">
            <a href="mailto:integrafs@aol.com">integrafs@aol.com</a>
            <div className="copyLink">
              <FiCopy className='copyIcon'/>
              <p>click here to copy</p>
            </div>
          </div>
        </div>
        <div className="recentPosts">
          <h2 className='bold'>Recent Posts</h2>
          <p>Coming soon!</p>
          {/* <ul>
            <li>Purchasing Power!</li>
            <li>DIY Tip of the Week</li>
            <li>Credit and Your New Mortgage</li>
          </ul> */}
        </div>
        <div className="badges">
          <img src="https://integra.imgix.net/EHO.jpg" alt="" />
        </div>
        <div className="nmls">
          <p>NMLS #7063</p>
          <p><a href="https://www.nmlsconsumeraccess.org/" target="_blank" rel="noreferrer">www.nmlsconsumeraccess.org</a></p>
          <p>
          <br/>
            Mortgage Loans in the
            <br/>
            States of Florida, Indiana,
            <br/>
            Kentucky and Virginia
          </p>
        </div>
      </div>
      <div className="container-max">
        <div className="container flex" id='footerSocial'>
          <div className="facebook">
            <a href="https://www.facebook.com/profile.php?id=100057223069744" rel='noopener noreferrer' target="_blank">
              <BsFacebook/>
            </a>
          </div>
          <div className="instagram">
            <a href="https://www.instagram.com/mortgage_guy_ky/" rel='noopener noreferrer' target="_blank">
              <BsInstagram/>
            </a>
          </div>
          <div className="instagram">
            <a href="https://mobile.twitter.com/skipbanister" rel='noopener noreferrer' target="_blank">
              <BsTwitter/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer