import React from 'react';
import { Helmet } from 'react-helmet'
import './styles/blog.css'
import axios from 'axios';
import {Link, Outlet} from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar'
import Expander from '../Nav/js/expandMobileNav'
import Collapser from '../Nav/js/collapseMobileNav'
import FormatDate from './js/formatDate';

// Built using the following as a guide: https://codesource.io/build-simple-blog-using-axios-with-react/

class Blog extends React.Component {
  state = {
    data: []
  }
  
  componentDidMount() {
    const url = "https://www.googleapis.com/blogger/v3/blogs/2815353594417403610/posts?key=AIzaSyDXs2EucC9mhnKlsoOv_mnVbQrvymaQmik";

    const config = {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: {},
    };

    axios.get(url, config)
    .then(res => {
      let newData = res.data.items.slice(0,5);
      this.setState({
        id: newData[newData.length - 1].id + 1,
        data: newData
      }, () => console.log(this.state.id))
      console.log(newData)
    })
    .catch(err => console.log("Couldn't fetch data. Error: " + err))
  }

  render() {

    return (
      <section className='container' id='blogRoot'>
        <Helmet>
          <title>Blog | Integra Mortgage, Home Loans in Louisville, LaGrange, Crestwood and Kentucky</title>
          <meta name='description' content='Mortgages in Louisville and the State of Kentucky' />
          <meta name='keywords' content='integra,mortgage,louisville,home,loans' />
        </Helmet>
      <div className="moduleOverlay" id='moduleOverlay' onClick={Collapser}></div>
      <div className="container-max" id="blogContent">
        <h1>Blog</h1>
        <p>Coming soon!</p>
        {/* <div id="blogContainer">
          {
            this.state.data.length === 0 ?
              <p>Loading Blog Posts...</p>
            :
            this.state.data.map((post, index) => (
              <div key={index} className="blogPost">
                <h2><Link to={post.title.replace(/ +/g, '-').replace(/[^\w\s\-]/gi, '').toLowerCase()}>{post.title}</Link></h2> The .replace function takes the post title and hyphen-conjoins each word, properly formatting it into the URL's permalink */}
                {/* <span className="blogDate">{FormatDate(post.published)}</span>
                <span className="blogAuthor">{post.author.displayName}</span>
                <p className='blogBody'>{post.content.substr(0, 150)}...</p> */}
                {/* Info on how to process json into actual html:
                https://stackoverflow.com/questions/43327766/render-html-from-a-json-string-in-react
                This will be helpful in properly formatting the blog content. */}
                {/* <a href={"blog/" + post.title.replace(/ +/g, '-').replace(/[^\w\s\-]/gi, '').toLowerCase()}>Read more...</a>
              </div>
            ))
          }
        </div> */}
      </div>
      <Sidebar />
      </section>
    )
  }
  
}

export default Blog;