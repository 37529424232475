import React from 'react'
import './styles/post.css'
// import axios from 'axios';
// import Sidebar from '../../Sidebar/Sidebar'
// import Expander from '../../Nav/js/expandMobileNav'
// import Collapser from '../../Nav/js/collapseMobileNav'
// import FormatDate from '../js/formatDate';

const Alotononesplate = () => {
  return (
    <article>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, possimus necessitatibus doloribus inventore ut voluptates soluta sequi fugiat saepe dolores accusantium doloremque eum, voluptate temporibus incidunt vitae optio blanditiis perferendis consectetur nihil illum reiciendis earum dolore modi. Magni incidunt pariatur modi atque deserunt laboriosam quasi, earum enim! Voluptas deleniti provident eos cupiditate accusantium pariatur quo tenetur sapiente ad natus sint in magnam error tempora nihil vel debitis minus itaque officia voluptatum fuga, aspernatur aliquam! Quod repellat voluptates pariatur eum quas non quibusdam sed. Cum voluptatibus sapiente repudiandae, nesciunt laborum nobis!
    </article>
  )
}

export default Alotononesplate