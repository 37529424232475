import React from 'react'
import { Helmet } from 'react-helmet'
import './styles/thank-you.css'
import Sidebar from '../Sidebar/Sidebar'
import Expander from '../Nav/js/expandMobileNav'
import Collapser from '../Nav/js/collapseMobileNav'

const ThankYou = () => {
  return (
    <section className='container' id='thankyouRoot'>
      <Helmet>
          <title>Contact Integra Mortgage Group in Louisville and LaGrange | Integra Mortgage, Home Loans in Louisville, LaGrange, Crestwood and Kentucky</title>
          <meta name='description' content='Thank you!' />
          <meta name='keywords' content='lagrange,la grange,crestwood,prospect,goshen,shelbyville,simpsonville,pewee valley,buckner,kentucky,louisivlle,home loan,real estate,mortgage' />
      </Helmet>
      <div className="moduleOverlay" id='moduleOverlay' onClick={Collapser}></div>
      <div className="container-max">
        <p className="thank-you">Thank you!<br></br>Someone will contact you soon!</p>
        <p><a href="https://integramortgagegroup.com/">Take me back home</a></p>
      </div>
    <Sidebar/>
    </section>
  )
}

export default ThankYou