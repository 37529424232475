import React from 'react'
import { Helmet } from 'react-helmet'
import './styles/contact.css'
import Sidebar from '../Sidebar/Sidebar'
import Expander from '../Nav/js/expandMobileNav'
import Collapser from '../Nav/js/collapseMobileNav'
import ContactForm from './ContactForm'

const Contact = () => {
  return (
    <section className='container' id='contactRoot'>
      <Helmet>
          <title>Contact Integra Mortgage Group in Louisville and LaGrange | Integra Mortgage, Home Loans in Louisville, LaGrange, Crestwood and Kentucky</title>
          <meta name='description' content='Contact Integra Mortgage Group in Louisville Kentucky for your real estate loan.' />
          <meta name='keywords' content='lagrange,la grange,crestwood,prospect,goshen,shelbyville,simpsonville,pewee valley,buckner,kentucky,louisivlle,home loan,real estate,mortgage' />
          <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      </Helmet>
      <div className="moduleOverlay" id='moduleOverlay' onClick={Collapser}></div>
      <div className="container-max" id='contactContent'>
        <h4>You can reach Integra Mortgage Group of Louisville at 502-314-5626 or submit the form below!</h4>
        <h1>Contact Us!</h1>
        <div className="contactFormWrapper">
          <ContactForm/>
        </div>
      </div>
    <Sidebar/>
    </section>
  )
}

export default Contact