import { React, useState } from 'react'
import { Helmet } from 'react-helmet'
import './styles/calculator.css'
import Sidebar from '../Sidebar/Sidebar'
import Expander from '../Nav/js/expandMobileNav'
import Collapser from '../Nav/js/collapseMobileNav'
import LoanJS from 'loanjs'

const Calculator = () => {
  const [values, setValues] = useState({
    "loan-amount": "",
    "loan-term": "",
    "interest-rate": ""
  });
  const [installments, setInstallments] = useState([]);

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    setValues({
      ...values,
      [name]: value
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    calculate(values["loan-amount"],
    values["loan-term"],
    values["interest-rate"]);
  }

  const calculate = (amount, years, rate) => {
    var loan = new LoanJS.Loan(amount, years * 12, rate);
    setInstallments(loan.installments);
  }

  const amountFormat = (amount) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(amount);

  return (
    <section className='container' id='calculatorRoot'>
      <Helmet>
          <title>Mortgage Calculator for Integra Mortgage Group in Louisville | Integra Mortgage, Home Loans in Louisville, LaGrange, Crestwood and Kentucky</title>
          <meta name='description' content='Integra Mortgage Group in Louisville and LaGrange Kentucky specializes in mortgage loans for new home purchases and refinances. Need a real estate loan in Louisville?' />
          <meta name='keywords' content='real estate,loan,mortgage,house,louisville,lagrange,la grange,crestwood,pewee valley,buckner,prospect,anchorage,shelbyville,simpsonville,fisherville,mount washington,brooks,shepherdsville' />
      </Helmet>
      <div className="moduleOverlay" id='moduleOverlay' onClick={Collapser}></div>
      <div className="container-max" id='calculatorContent'>
        <h1>Mortgage Calculator</h1>
        <p>This calculator is designed to give you an estimated payment on a new mortgage. Final interest rate and down payment may change upon final approval.</p>
        <div className="calculator-wrapper">
          <div className="container-max flex form-wrapper">
            <form onSubmit={handleSubmit} className="flex flex-column">
              <div className="form-item">
                <label htmlFor='loan-amount'>Loan Amount</label>
                <div className="form-input">
                  <input
                    type="number"
                    min="0"
                    name="loan-amount"
                    placeholder="0"
                    value={values["loan-amount"]}
                    onChange={handleInputChange}
                    />
                </div>
              </div>
              <div className="form-item">
                <label htmlFor='interest-rate'>Interest Rate</label>
                <div className="form-input">
                  <input
                    type="number"
                    min="0"
                    step="0.001"
                    name="interest-rate"
                    placeholder="0"
                    value={values["interest-rate"]}
                    onChange={handleInputChange}
                    />
                </div>
              </div>
              <div className="form-item">
                <label htmlFor='loan-term'>Loan Term (Years)</label>
                <div className="form-input">
                  <input
                    type="number"
                    min="0"
                    name="loan-term"
                    placeholder="0"
                    value={values["loan-term"]}
                    onChange={handleInputChange}
                    />
                </div>
              </div>
              <div className="form-action">
                <input
                  type="submit"
                  value="Calculate"
                  className='calculate-button'
                  />
              </div>
            </form>
            <div className="container-max estimatedPayment flex flex-column flex-jc-c flex-ai-c">
              <h2>Your estimated monthly payment is</h2>
              {installments.map((i) => (
                <span>{amountFormat(i.installment)}</span>
              ))[0]}
            </div>
          </div>
          {!!installments?.length && (
            <div className='table-wrapper'>
            <h2>Amortization Schedule</h2>
            <table>
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Payment Amount</th>
                  <th>Interest Paid</th>
                  <th>Principal Paid</th>
                  <th>Remaining</th>
                </tr>
              </thead>

              <tbody>
                {installments.map((i, ind) => (
                  <tr key={ind}>
                    <td>{ind}</td>
                    <td>{amountFormat(i.installment)}</td>
                    <td>{amountFormat(i.interest)}</td>
                    <td>{amountFormat(i.capital)}</td>
                    <td>{amountFormat(i.remain)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          )}
        </div>
      </div>
      <Sidebar />
    </section>
  )
}

export default Calculator
