import React from 'react'
import ReactDOM from 'react-dom'
import Nav from './components/Nav/Nav'
import Sidebar from './components/Sidebar/Sidebar'
import Home from './components/Home/Home'
import About from './components/About/About'
import Testimonials from './components/Testimonials/Testimonials'
import Blog from './components/Blog/Blog'
import MortgagePrograms from './components/MortgagePrograms/MortgagePrograms'
import Calculator from './components/Calculator/Calculator'
import FAQ from './components/FAQ/FAQ'
import Contact from './components/Contact/Contact'
import Apply from './components/Apply/Apply'
import Footer from './components/Footer/Footer'
import NotFound from './components/NotFound/NotFound'
import ThankYou from './components/ThankYou/ThankYou'

import Alotononesplate from './components/Blog/a-lot-on-ones-plate'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
    ReactDOM.render(
        <Router>
            <Nav />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/a-lot-on-ones-plate" element={<Alotononesplate />} />
                <Route path="/mortgage-programs" element={<MortgagePrograms />} />
                <Route path="/calculator" element={<Calculator />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/apply" element={<Apply />} />
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </Router>,
        document.getElementById("root")
    );
    return (<></>)
}

export default App