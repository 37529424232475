import React, { Component} from 'react';
import { FacebookProvider, Page } from 'react-facebook';
 
export default class IntegraFbPage extends Component {
  render() {
    return (
      <FacebookProvider appId="510382057244713">
        <Page href="https://www.facebook.com/profile.php?id=100057223069744" width="230" />
      </FacebookProvider>    
    );
  }
}