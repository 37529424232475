import React, { useState, useMemo } from 'react';
import { Helmet } from 'react-helmet'
import Pagination from '../Pagination/Pagination';
import data from './data/testimonials.json';
import './styles/testimonials.css'
import Sidebar from '../Sidebar/Sidebar'
import Expander from '../Nav/js/expandMobileNav'
import Collapser from '../Nav/js/collapseMobileNav'

let PageSize = 4;

export default function App() {
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <>
      <section className='container' id='testimonialRoot'>
        <Helmet>
          <title>What our customers are saying about Integra Mortgage Group in Louisville and LaGrange, Ky | Integra Mortgage, Home Loans in Louisville, LaGrange, Crestwood and Kentucky</title>
          <meta name='description' content='Testimonials from mortgage customers of Integra Mortgage Group after they get their home loan.' />
          <meta name='keywords' content='integra,mortgage,louisville,home,loans' />
        </Helmet>
      <div className="moduleOverlay" id='moduleOverlay' onClick={Collapser}></div>
      <div className="container-max" id='testimonialContent'>
        <h1>Testimonials</h1>
        <section className='cardWrapper'>
          {currentTableData.map(item => {
              return (
                <article className='card'>
                  <h3 className="header">{item.displayName}</h3>
                  <div className="meta">
                    <span className="locale">{item.locale}</span>
                    <span className="date">{item.date}</span>
                  </div>
                  <div className="content" dangerouslySetInnerHTML={{__html:item["content"]}}></div>
                </article>
              );
            })
          }
        </section>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
      <Sidebar />
      </section>
    </>
  );
}