import React from 'react'
import IntegraFbPage from '../FbPlugin/FbPlugin'
import { BsLinkedin, BsPinterest } from 'react-icons/bs'
import './styles/sidebar.css'

const Sidebar = () => {
  return (
    <div className='sidebar'>
        <div className="sb-contact">
            <h3>Contact Us</h3>
            <p>Integra Mortgage Group LLC</p>
            <div className="phone flex nobreak">
                <a href="tel:+15023145626">502-314-5626</a>
            </div>
            <div className="email flex nobreak">
                <a href="mailto:integrafs@aol.com">integrafs@aol.com</a>
            </div>
        </div>
        <div className="sb-social">
            <div className="sb-facebook sb-social-vendor">
                <IntegraFbPage />
            </div>
            <div className="sb-LinkedIn flex flex-ai-c sb-social-vendor">
                <BsLinkedin/>
                <a href="https://www.linkedin.com/in/skip-banister-17a46711/" rel='noopener noreferrer' target={'blank'}>
                    Skip Banister
                </a>
            </div>
            <div className="sb-pinterest flex flex-ai-c sb-social-vendor">
                <BsPinterest/>
                <a href="https://www.pinterest.com/integramortgage/" rel='noopener noreferrer' target={'blank'}>
                    Integra on Pinterest!
                </a>
            </div>
        </div>
        <div className="sb-blog-posts">
            <h3>Recent Stories</h3>
            <p>Coming soon!</p>
            {/* <ul>
                <li>Purchasing Power!</li>
                <li>DIY Tip of the Week</li>
                <li>Credit and Your New Mortgage</li>
            </ul> */}
        </div>
    </div>
  )
}

export default Sidebar