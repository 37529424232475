import { React, useEffect, useState } from 'react'
import './styles/nav.css'
import {AiFillCaretDown} from 'react-icons/ai'
import {BiMenu} from 'react-icons/bi'
import {IoMdClose} from 'react-icons/io'
import {NavLink} from "react-router-dom"
import Expander from './js/expandMobileNav'
import Collapser from './js/collapseMobileNav'

function Nav() {
    const [nav, setNav] = useState(false);

    const shrinkNav = () => {
        if(window.scrollY >= 75) {
            setNav(true);
        } else {
            setNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', shrinkNav);
    }, []);

    return (
        <>
        <nav className={nav ? 'container-max scrolled' : 'container-max'} id='navRoot'>
            <button className="hamburger" onClick={Expander}>
                <BiMenu />
            </button>
            <div className="container flex flex-jc-sb">
                <div className="brand">
                    <img src="https://integra.imgix.net/logos/Logo_Original.png" alt="Integra Mortgage Group logo" />
                </div>
                <ul className='navItems' id="main-nav">
                    <div className='closeMobileMenu-wrapper'>
                        <button className='closeMobileMenu' onClick={Collapser}>
                            <IoMdClose />
                        </button>
                    </div>
                    <li><NavLink to="/" className='nav-link' onClick={Collapser}>Home</NavLink> </li>
                    <li className='dropdownParent'>
                        <p to="/about" className='nav-link'>About
                            <div className="dropdownIcon">
                                <AiFillCaretDown/>
                            </div>
                        </p> 
                        <div className="dropdownMenu">
                            <ul>
                                <li><NavLink to="/about" className='nav-link' onClick={Collapser}>About Us</NavLink> </li>
                                <li><NavLink to="/testimonials" className='nav-link' onClick={Collapser}>Testimonials</NavLink> </li>
                                <li><NavLink to="/blog" className='nav-link' onClick={Collapser}>Blog</NavLink> </li>
                            </ul>
                        </div>
                    </li>
                    <li className='dropdownParent'>
                        <p className='nav-link'>Tools
                            <div className="dropdownIcon">
                                <AiFillCaretDown/>
                            </div>
                        </p> 
                        <div className="dropdownMenu">
                            <ul>
                                <li><NavLink to="/mortgage-programs" className='nav-link' onClick={Collapser}>Mortgage Programs</NavLink></li>
                                <li><NavLink to="/calculator" className='nav-link' onClick={Collapser}>Mortgage Calculator</NavLink> </li>
                                <li><NavLink to="/faq" className='nav-link' onClick={Collapser}>FAQs</NavLink> </li>
                            </ul>
                        </div>
                    </li>
                    <li><NavLink to="contact" className='nav-link' onClick={Collapser}>Contact Us</NavLink> </li>
                    <li><NavLink to="apply" className='nav-link' onClick={Collapser}>Apply</NavLink> </li>
                </ul>
            </div>
        </nav>
        </>
    )
}

export default Nav