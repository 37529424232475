import React from 'react'
import { Helmet } from 'react-helmet'
import './styles/home.css'
import '../Card/styles/card.css'
import Expander from '../Nav/js/expandMobileNav'
import Collapser from '../Nav/js/collapseMobileNav'
import { ReactComponent as ScrollDown } from '../../assets/scroll.svg'
import {MdOutlineKeyboardArrowRight} from 'react-icons/md'

const HomeMain = () => {
  return (
    <React.Fragment>
      <Helmet>
          <title>Integra Mortgage Group - Turning Dreams into Reality!</title>
          <meta name='description' content='Now serving Florida, Virginia, and Kentucky! Integra Mortgage in Louisville Kentucky specializes in mortage loans for new home purchases and for refinances.' />
          <meta name='keywords' content='mortgage broker louisville, mortgage louisville, integra, mortgage, louisville, kentucky, florida, virginia, home, house, refinance, purchase, skip banister' />
      </Helmet>
      <div className="container flex flex-column" id='homeRoot'>
        <div className="moduleOverlay" id='moduleOverlay' onClick={Collapser}></div>
        
        <div className="home-background">
          <div className="bg-overlay"></div>
        </div>

        <div className="flex flex-column flex-jc-c" id='homeTitle'>
          <h1>Integra Mortgage Group, LLC</h1>
          <h2>Your home loan experts in Florida, Indiana, Kentucky and Virginia.</h2>
          <div className="cta-wrapper">
            <a href="https://integramortgagegroup.com/mortgage-programs"><button className="btn btn-primary btn-home">Mortgage Programs<MdOutlineKeyboardArrowRight/></button></a>
            <a href="https://www.blink.mortgage/app/signup/p/integramortgagegroupllc"><button className="btn btn-primary btn-home">Apply Online Now!<MdOutlineKeyboardArrowRight/></button></a>
          </div>
        </div>
        <div className="flex flex-jc-c" id='homeScrollIndicator'>
          <a href="#homeInfo" className='scrollDown'>
            <ScrollDown />
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}

const HomeInfo = () => {
  return (
    <React.Fragment>
      <div className="container flex flex-column" id="homeInfo">
        <div className="text-block">
          <h1 className='text-bold'>Putting Customers First</h1>
          <p className='text-light'>Integra Mortgage Group LLC began as a resource to serve the mortgage needs of the Louisville, Kentucky area but has since expanded to include Florida, Indiana and Virginia. Our promise to you is that we will find the mortgage program best suited for your situation. Whether it's a home purchase, new construction, refinance, debt consolidation, or you just need cash from the equity in your home for any reason, Integra Mortgage Group LLC will be the first <em>and last</em> stop for your mortgage needs!</p>
        </div>
        <div className="container-max flex flex-jc-sb card-wrapper">
          <div className="card">
            <div className="cta-id">
              <h1>Mortgage Calculator</h1>
              <h2>Calculate your payment</h2>
            </div>
            <div className="btn btn-primary">
              <a href="https://integramortgagegroup.com/calculator">Calculate Now</a>
            </div>
          </div>
          <div className="card">
          <div className="cta-id">
              <h1>Testimonials</h1>
              <h2>What are people saying about us?</h2>
            </div>
            <div className="btn btn-primary">
              <a href="https://integramortgagegroup.com/testimonials">Discover!</a>
            </div>
          </div>
          <div className="card">
          <div className="cta-id">
              <h1>Apply in a Blink!</h1>
              <h2>Ready to apply for a mortgage?</h2>
            </div>
            <div className="btn btn-primary">
              <a href="https://integramortgagegroup.com/apply">Apply Now!</a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Home = () => {
  return (
    <>
      <HomeMain></HomeMain>
      <HomeInfo></HomeInfo>
    </>
  )
}

export default Home
